.section.walloftext
  padding-top: 160px
  padding-bottom: 160px
  max-height: none
  max-width: 1024px
  margin-left: auto
  margin-right: auto
  text-align: left

  h1, h2, h3, h4, h5, h6
    margin-bottom: 0.6em
    margin-top: 1.2em

  h3, h4
    font-weight: bold

  p, ul, ol
    margin-bottom: 1em

  p, li
    line-height: 1.3em
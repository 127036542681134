//Colors

$white: #FFFFFF
$greywhite: #ADADAD52
$black: #1A1A1A
$light-black: #333333
$primary: #E9FF43
$secondary: #F9EC4E

//Breakpoints
$xs: 320px
$sm: 640px
$md: 768px
$lg: 1024px
$xl: 1280px
$xxl: 1536px
$wqhd: 2400px

//Vulcano Colors for Projects
$c-white: #fff
$c-black: #000
$c-red: #F3073F
$c-darkgrey: rgba(35, 35, 35, 1)
$c-lightgrey: rgba(200, 200, 200, 1)
$c-grey: rgba(35, 35, 35, 0.32)
//$c-orange: rgba(251, 181, 61, 1)
$c-orange: $primary
$olive: #26A69A
$c-darkgrey: #232323
$bg: #191919
$lines: #757474


$c-primary: $c-lightgrey

.section.contact
    display: flex
    justify-content: center
    align-items: center
    
.contact-form
    display: flex
    flex-direction: column
    margin-top: 80px

    @media screen and (min-width: $md)
        flex-direction: row

    &__header
        text-align: left

        h2,i
            font-weight: bold
            display: inline-block
        i
            color: $greywhite

    form
        width: 100%
        margin-top: -34px
        display: flex
        flex-direction: column

        @media screen and (min-width: $md)
            width: 55%

    &__row
        white-space: nowrap
        display: flex
        justify-content:  space-between

        .input
            width: 45%

    &__info
        width: 100%
        text-align: left
        margin-top: 80px

        @media screen and (min-width: $md)
            width: 45%
            max-width: 280px
            margin-left: 80px
            margin-top: 0

        h4
            font-weight: bold

        p
            margin-top: 24px
            font-size: 16px
            line-height: 22px

    &__wrapper
        max-width: 100%
        

.hr-one
    border-top: none rgb(224, 224, 224)
    border-left: none rgb(224, 224, 224)
    border-right: none rgb(224, 224, 224)
    border-bottom: 1px solid rgb(224, 224, 224)
    bottom: 8px
    box-sizing: content-box
    margin: 0px
    position: absolute
    width: 100%

.hr-two
    border-top: none rgb(33, 33, 33)
    border-left: none rgb(33, 33, 33)
    border-right: none rgb(33, 33, 33)
    border-bottom: 2px solid rgb(33, 33, 33)
    bottom: 8px
    box-sizing: content-box
    margin: 0px
    position: absolute
    width: 100%
    transform: scaleX(0)
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms

.nav
  max-width: 100%
  position: absolute
  left: 0
  right: 0
  top: 0
  z-index: 10

  &.with-background
    background: $light-black
  
  @media screen and (min-width: $sm)
    padding-top: 24px
    padding-bottom: 24px
    display: flex
    justify-content: space-between
    align-items: center

  &__bubble
    width: 64px
    height: 64px
    border-radius: 50%
    background-color: $primary
    display: inline-flex
    justify-content: center
    align-items: center
    cursor: pointer
    margin-left: 10px

  &__logo
    display: inline-block
    position: relative

    p, span
      display: inline-block
      color: white
      font-size: 20px

    // p 
    //   transform: translate(0px, 0px)

    // span:first-of-type
    //  transform: translateY(-4px)

    //span:last-of-type
    //  transform: translateY(4px)

    img
      height: 100%
      width: 100%

  &__menu
    max-width: 360px
    display: none
    position: relative

    @media screen and (min-width: $lg)
      display: block

  &__links
    list-style: none

    li
      display: inline-block
      list-style: none

    li a
      text-decoration: none
      color: $black

  .bounce-button
    display: block

    b
      color: $white

    .bounce-left
      -webkit-animation: bounce-left 0.8s both
      animation: bounce-left 0.8s both


.mac-stage
  position: relative
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  z-index: 1

  &__header
    display: block
    max-width: 1920px
    padding: 64px 120px 14px 120px
    z-index: 2
    background: black
    top: 0
    right: 0
    left: 0

    h3
      text-align: center
      color: $white

      b
        color: $primary

  &__container
    height: 100%
    width: 100%
    position: relative

  &__stage
    display: block
    width: 100%
    max-width: 100%
    position: relative
    z-index: -1

    // &:before
    //   display: block
    //   content: ""
    //   width: 100%
    //   padding-top: calc(767 / 1150 * 100%)

    & > .inner
      // position: absolute
      top: 0
      right: 0
      line-height: 0
      bottom: 0
      left: 0

      img
        width: 100%

  &__box
    height: 27.3vw
    width: 43.5vw
    position: absolute
    top: 5%
    left: 50%
    opacity: 1
    transform: translate(-50%, 0%)

  &__browser
    position: relative
    width: 100%
    height: 100%
    overflow: hidden

  &__references
    z-index: 3
    position: relative
    left: 0
    bottom: 0
    right: 0
    transform: none
    background-color: $black

    @media screen and (min-width: $lg)
      position: absolute
      transform: translateY(-100%)
      background-color: transparent

    ul
      color: $white
      text-align: left
      font-weight: bold
      display: flex
      width: 60%
      flex-wrap: wrap
      margin: 0 auto
      list-style: none
      justify-content: center
      flex-direction: column 
      text-align: center

      @media screen and (min-width: $lg)
        flex-direction: row
        

      li
        margin: 12px 0
        cursor: pointer
        font-size: 16px
        border-radius: 4px
        transition: 0.1s 0s ease-in-out
        padding: 14px 32px
        background-color: transparent
        color: $white
        font-weight: normal

        &.active
          background-color: $primary
          color: $black
          font-weight: bold

        &:not(:last-of-type)
          margin-right: 0px

          @media screen and (min-width: $lg)
            margin-right: 24px

        &:not(.active):hover, &:not(.active):focus
          transition: 0.1s 0s ease-in-out
          background-color: rgba($greywhite, 0.4)


.padding
  padding: 24px
  
  @media screen and (min-width: $md)  
    padding: 42px

  @media screen and (min-width: $lg)
    padding: 80px

  @media screen and (min-width: $xl)
    padding: 100px

  &--lr
    padding: 0 24px
    
    @media screen and (min-width: $md)  
      padding: 0 42px

    @media screen and (min-width: $lg)
      padding: 0 80px

    @media screen and (min-width: $xl)
      padding: 0 100px

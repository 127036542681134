body 
  margin: 0
  font-family: "Helvetica Neue"
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale


code 
  font-family: monospace


.process
  height: 100%

  &__stepper
    padding: 100px
    width: 100%
    height: 100%
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between

    &:after, &:before
      content: ''
      display: block
      background: linear-gradient($black, transparent)
      position: absolute
      height: 38px
      top: 10px
      left: 0
      z-index: 2
      right: 0
      width: 100%

    &:before
      z-index: 5
      background: linear-gradient(transparent, $black)
      top: auto
      bottom: 10px

    &-goal
      max-width: 260px
      width: 100%
      cursor: pointer
      color: white
      position: relative
      left: 100%
      transform: translateX(-100%) scale(0.8)
      transition: all 0.2s ease-in-out
      opacity: 0.64
      z-index: 3
      text-align: left
      transform-origin: top

      h5
        margin-bottom: 16px

      strong
        font-weight: bold
        color: $primary

      &--active
        left: 0
        transform: scale(1.2)
        opacity: 1
        transform-origin: top
        transition: all 0.3s ease-in-out



    &-bubble
      width: 24px
      height: 24px
      border-radius: 50%
      background-color: $white
      display: block
      position: relative
      z-index: 10
      cursor: pointer
      transform: scale(0.4)
      transition: all 0.3s ease-in-out

      &:after
        content: ''
        position: absolute
        width: 100%
        height: 100%
        border-radius: 50%
        background-color: $secondary
        z-index: 1
        opacity: 0.5
        left: 50%
        top: 50%
        transform: translate(-50%, -50%) scale(1.5)
        display: none

      &:before
        content: ''
        position: absolute
        width: 100%
        height: 100%
        border-radius: 50%
        background-color: $secondary
        z-index: 1
        opacity: 0.3
        left: 50%
        top: 50%
        transform: translate(-50%, -50%) scale(3)
        display: none

      &--active
        background-color: $secondary
        transform: scale(1)
        transition: all 0.3s ease-in-out

        &:after, &:before
          display: block

    &-steps
      z-index: 2
      position: absolute
      top: 0
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 100px 0
      left: 50%
      bottom: 0
      transform: translateX(-50%)

      &:after
        content: ''
        display: block
        height: 98%
        width: 2px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background-color: $white

  &__states
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center

  &__state
    max-height: 670px
    max-width: 670px
    width: 100%
    height: 100%
    display: none
    //opacity: 0
    text-align: left

    &--active
      //opacity: 1
      display: block

    &-img
      width: 674px
      height: 379px
      border-radius: 16px
      overflow: hidden

      img
        width: 100%
        height: 100%
        object-fit: cover

    p
      max-width: 550px
      font-size: 18px
      line-height: 25px
      margin-top: 24px

    h1
      margin-top: 80px

      strong
        font-weight: bold

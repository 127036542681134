h1, h2, h3, p, a, li, div, span
  font-family: "AvenirNext", sans-serif
  font-size: 18px

h1
  font-size: 52px
  line-height: 52px

  @media screen and (min-width: $md)
    font-size: 70px
    line-height: 74px

  @media screen and (min-width: $lg)
    font-size: 92px
    line-height: 112px

  @media screen and (min-width: $xl)
    font-size: 96px
    line-height: 120px

h2
  font-size: 60px
  line-height: 74px

  @media screen and (min-width: $md)
    font-size: 82px
    line-height: 104px

  @media screen and (min-width: $lg)
    font-size: 92px
    line-height: 112px

  @media screen and (min-width: $xl)
    font-size: 96px
    line-height: 120px

.contact-form__header h2, .contact-form__header h2 i
  font-size: 40px
  line-height: 44px

  @media screen and (min-width: $md)
    font-size: 64px
    line-height: 100px

h3
  font-size: 32px
  line-height: 48px

  @media screen and (min-width: $md)
    font-size: 36px
    line-height: 54px

  @media screen and (min-width: $lg)
    font-size: 42px
    line-height: 60px

  @media screen and (min-width: $xl)
    font-size: 48px
    line-height: 64px

h4
  font-size: 24px
  line-height: 32px

h5
  font-size: 22px
  line-height: 30px

.slider
  display: block
  overflow: scroll
  width: 100%
  max-width: 100%

  &__container
    border: 1px solid green
    width: 100%
    max-width: 100vw
    padding: 80px
    margin: 0 auto

  &__header
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-direction: column
    margin-bottom: 24px

    h2
      font-weight: bold
      font-size: 42px
      line-height: 1
      margin-bottom: 12px

    h4
      line-height: 1
      font-weight: bold
      font-size: 32px

  &__slide
    display: flex
    min-width: max-content
    justify-content: flex-start
    align-items: flex-start

  &__element
    border: 1px solid red
    width: 100%
    height: 100%
    flex: 1 1
    max-width: 398px
    min-width: 398px
    max-height: 516px

    & > div
      text-align: left
      padding: 24px 32px
      font-weight: bold

    img
      display: block
      object-fit: cover
      width: 100%
      height: auto

    &:not(:last-of-type)
      margin-right: 24px


.footer
    width: 100%
    height: 100%
    max-height: 460px
    padding: 100px 24px
    background-color: $black
    color: $white
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    &__header
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center

    &__container
        margin-top: 52px
        max-width: 600px
        width: 100%
        text-align: center
        color: $white

        .copyright, p
            text-align: center
            width: 100%

        .copyright
            margin-top: 52px

    &__links
        margin-top: 12px
        justify-content: center
        display: flex
        width: 100%

        a, a:visited
            color: $primary
            text-decoration: none

            &:hover, &:focus
                text-decoration: underline

        li
            margin: 0 8px





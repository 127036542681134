.margin
  margin: 24px
  
  @media screen and (min-width: $md)  
    margin: 42px

  @media screen and (min-width: $lg)
    margin: 80px

  @media screen and (min-width: $xl)
    margin: 100px

  &--lr
    margin: 0 24px
    
    @media screen and (min-width: $md)  
      margin: 0 42px

    @media screen and (min-width: $lg)
      margin: 0 80px

    @media screen and (min-width: $xl)
      margin: 0 100px

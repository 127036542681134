.main
  width: 100%
  height: 100%
  background-color: white

  .first
    overflow: hidden
    &:after
      content: ''
      display: block
      background: transparent linear-gradient(180deg, #00000000 0%, #00000031 36%, #00000093 71%, #000000 100%) 0% 0% no-repeat padding-box
      width: 100%
      height: 15%
      position: absolute
      bottom: 0

    .splitter
      display: flex
      &:first-of-type
        background-color: $light-black

      &__content
        //position: absolute
        //left: 120px
        //top: 33%
        //transform: translateY(-50%)
        //width: 400px
        //text-align: left
        //z-index: 2
        text-align: left
        z-index: 2
        padding: 148px 100px
        color: white


      .human
        max-width: 100vw
        object-fit: cover
        position: absolute
        bottom: -20%
        margin-top: auto
        height: 70vw
        max-height: 100%
        padding-top: 10%
        right: -10%
        left: auto

        &.faller
          right: auto
          left: -10%
          padding-top: 5%

  .😏😁👍😎
    //color: floralwhite
    //font-family: monospace
    //line-height: 32px
    //font-size: 24px

.hero-banner
  position: absolute
  width: 100%
  bottom: 0
  z-index: 5
  height: 60px
  display: flex
  justify-content: center
  align-items: center
  backdrop-filter: blur(30px)
  background-color: rgba($black, 0.6)
  color: $white

  &--bright
    backdrop-filter: none
    background-color: $white
    color: $light-black


  span
    margin: 0 4px

.input 
    font-size: 16px
    line-height: 24px
    width: 100%
    height: 72px
    display: inline-block
    position: relative
    background-color: transparent
    font-family: Roboto, sans-serif
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms
    cursor: auto
    text-align: left

    &:focus-within label,
    & input:valid + label,
    & input:not(:placeholder-shown) + label
        line-height: 22px
        top: 38px
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms
        z-index: 1
        transform: scale(0.75) translate(0px, -28px)
        transform-origin: left top
        pointer-events: none
        user-select: none
        color: rgba(0, 0, 0, 0.3)
    
    input
        appearance: textfield
        padding: 0px
        position: relative
        width: 100%
        border: none
        outline: none
        background-color: rgba(0, 0, 0, 0)
        color: rgba(0, 0, 0, 0.87)
        cursor: inherit
        font: inherit
        opacity: 1
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
        height: 100%
        box-sizing: border-box
        margin-top: 14px

    input[type="submit"]
        background: none
        color: #000
        width: 160px
        height: 50px
        border: 1px solid #000
        font-size: 18px
        overflow: hidden
        transition: all .2s
        border-radius: 0
        cursor: pointer
        transition: all 1s

        &:hover, &:focus
            background: $black
            color: $primary
            transition: all 0.1s

    label
        position: absolute
        line-height: 22px
        top: 38px
        left: 0
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms
        z-index: 1
        transform: scale(1) translate(0px, 0px)
        transform-origin: left top
        pointer-events: none
        user-select: none
        color: rgba(0, 0, 0, 0.3)

    
    

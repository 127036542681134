@font-face
  font-family: "Helvetica Neue"
  src: url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.eot")
  src: url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.woff") format("woff"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.svg#Helvetica") format("svg")

@font-face
  font-family: "BastligaOne"
  font-weight: normal
  src: url("../../assets/fonts/BastligaOne.otf") format("opentype")

@font-face
  font-family: "AvenirNext"
  font-weight: bold
  src: url("../../assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype")

@font-face
  font-family: "AvenirNext"
  font-style: italic
  src: url("../../assets/fonts/AvenirNextLTPro-It.otf") format("opentype")

@font-face
  font-family: "AvenirNext"
  font-weight: normal
  src: url("../../assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype")

.portfolio-slider
  $primary-color: #e5ff00
  $text-color: #ffffff
  $background-color: #1a1a1a
  $transition-duration: 0.5s
  background-color: $background-color
  color: $text-color
  padding: 66px 0 124px 0

  &__header
    text-align: center
    margin-bottom: 4rem

  &__title
    font-size: 2.5rem
    margin-bottom: 1rem


  &__subtitle
    font-size: 2rem
    color: $primary-color


  &__container
    position: relative
    display: flex
    align-items: center
    overflow: hidden

  &__content
    position: relative
    width: 100%
    margin: 0 auto
    max-width: 1200px

  &__slide
    transition: all ease-in-out .2s
    opacity: 1
    display: flex
    flex-direction: column
    margin: 0 24px
    @media screen and (min-width: 1500px)
      flex-direction: row

    &:not(.active)
      opacity: 0.32

  &__image-container
    //max-height: 420px
    aspect-ratio: 660 / 371
    max-width: 880px
    min-width: 360px
    overflow: hidden
    border-radius: 1rem

  &__image
    width: 100%
    height: 100%
    object-fit: cover


  &__info
    flex: 1
    padding: 2rem
    text-align: left
    max-width: 420px

  &__project-title
    font-size: 2rem
    margin-bottom: 0.5rem


  &__project-subtitle
    font-size: 1.5rem
    margin-bottom: 1.5rem


  &__tags
    display: flex
    gap: 1rem
    margin-bottom: 1.5rem
    white-space: nowrap


  &__tag
    background-color: rgba($primary-color, 0.1)
    color: $primary-color
    padding: 0.5rem 1rem
    border-radius: 2rem
    font-size: 0.875rem


  &__description
    margin-bottom: 2rem
    line-height: 1.6


  &__link
    color: $primary-color
    text-decoration: none
    display: flex
    align-items: center
    gap: 0.5rem

    &:hover
      text-decoration: underline


  &__nav
    background: transparent
    border: none
    color: $primary-color
    cursor: pointer
    padding: 1rem
    position: absolute
    top: 50%
    transform: translateY(-50%)
    z-index: 10
    display: none

    @media screen and (min-width: $lg)
      display: block

    svg g
      fill: $primary-color

    &--prev
      left: 25px

    &--next
      right: 25px

      svg
        transform: rotate(180deg)

    &-icon, svg
      width: 2rem
      height: 2rem


  &__dots
    display: flex
    justify-content: center
    gap: 1rem
    margin-top: 2rem
    cursor: pointer

  &__dot
    width: 1rem
    height: 1rem
    border-radius: 50%
    border: 2px solid $primary-color
    background: transparent
    cursor: pointer
    padding: 0

    &.active
      background-color: $primary-color

.slick-slider
  overflow: hidden
  padding-bottom: 48px

.slick-dots
  bottom: 0 !important

  li
    cursor: pointer

.slick-prev
  left: 25px !important

.slick-next
  right: 25px !important


.slick-slide > div > div
  width: fit-content !important
//margin: 0 -24px !important

.bounce-left
  -webkit-animation: bounce-left 0.8s both
  animation: bounce-left 0.8s both

.slide-out-right
  -webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both
  animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both


@keyframes slide-out-right 
  0% 
    transform: translateX(0)
    opacity: 1

  100% 
    transform: translateX(1000px)
    opacity: 0
  
@keyframes bounce-left
  0%
    transform: translateX(-48px)
    animation-timing-function: ease-in
    opacity: 1

  24%
    opacity: 1

  40%
    transform: translateX(-26px)
    animation-timing-function: ease-in

  65%
    transform: translateX(-13px)
    animation-timing-function: ease-in

  82%
    transform: translateX(-6.5px)
    animation-timing-function: ease-in

  93%
    transform: translateX(-4px)
    animation-timing-function: ease-in

  25%,
        55%,
        75%,
        87%,
        98%
    transform: translateX(0px)
    animation-timing-function: ease-out

  100%
    transform: translateX(0px)
    animation-timing-function: ease-out
    opacity: 1
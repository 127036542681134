.detail-bar
  display: flex
  flex: 1 1
  background-color: $c-grey
  padding: 80px 55px

  &__container
    margin-top: 40px

  &__left
    flex: 1 1


    p
      margin-bottom: auto

  &__right
    flex: 1 1
    display: flex
    flex-wrap: wrap
    justify-content: flex-end
    margin: -10px

    img
      margin: 10px



@font-face
  font-family: system
  font-style: normal
  font-weight: 300
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light")

.window
  color: red
  background-color: transparent
  border-radius: 7px 7px 7px 7px
  border-color: transparent
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px
  height: 759px /* title bar height + content height */
  width: 60%
  position: absolute
  overflow: hidden

  &--hidden
    display: none
    pointer-events: none
    visibility: hidden

  &--default
    width: 80%
    left: 50%
    top: 50%
    height: 90%
    transform: translate(-50%, -50%)

  &--enlarged
    transform: none
    left: 0 !important
    top: 0 !important
    width: 100%
    height: 100%

  &__content
    width: 100%

  .title-bar-width
    width: calc(100% + 2px)

  #logo
    padding-left: 5px
    vertical-align: middle

  #logo-description
    color: #404040
    font-size: 13px
    font-family: "system", sans-serif
    vertical-align: middle
    margin-right: 30px
    font-weight: bold

  #url-bar
    height: 28px
    background-color: #ededee
    border-bottom: 1px solid lightgray
    width: 100%
    display: flex
    align-items: center
    white-space: nowrap
    overflow: scroll
    text-overflow: ellipsis
    -ms-overflow-style: none
    scrollbar-width: none

  #url-bar::-webkit-scrollbar
    display: none

  #ssl-padlock
    user-select: none
    padding-left: 8px
    margin-right: 8px

  #domain-name
    color: black
    font-size: 14px
    font-family: "system", sans-serif

  #domain-path
    color: #878383
    font-size: 14px
    font-family: "system", sans-serif

  .title-bar-width
    width: calc(100% + 2px) /* 2px added to allow for border on iframe */

  #title-bar
    user-select: none
    text-align: center

  &__content
    width: calc(150% + 2px)
    height: calc(150% - 87px)
    border-bottom-right-radius: 7px
    border-bottom-left-radius: 7px
    transform: scale(0.667) translate(-25%, -25%)

  .outer
    background: #b4b4b4
    opacity: 0.9
    padding: 5px 5px 5px 8px
    border-radius: 7px 7px 0 0

    &.dark
      background: #273138

      & .dot
        background: #4a5c66

    &.yosemite
      background: #f6f6f6
      border: 1px solid #d8d8d8
      border-bottom: 1px solid #b4b4b4

  .dots
    float: left

  .dot
    display: inline-block
    width: 10px
    height: 10px
    background: #f9f9f9
    border-radius: 50%
    margin: 0 4px 0 0
    cursor: pointer

    &.red
      background: #ff6057
      border: 1px solid #e14640

    &.amber
      background: #ffbd2e
      border: 1px solid #dfa123

    &.green
      background: #27c93f
      border: 1px solid #1dad2b




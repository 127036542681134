*
  scroll-behavior: smooth
  box-sizing: border-box

img
//width: 100%
//height: auto

.App
  text-align: center
  height: 100%
  min-height: 100vh
  background-color: darkslategrey

.row
  display: flex
  flex-direction: row

.section
  height: 100%
  max-height: 100vh
  min-height: 100vh
  height: stretch
  position: relative


  &.fifth
    max-height: unset
    min-height: unset

    @media screen and (min-width: $lg)
      min-height: 100vh

b
  font-weight: bold

.splitter
  max-height: 100%
  height: 100vh
  max-width: 50%
  width: auto
  flex: 1 1
  position: relative


.content
  margin: 0 auto
  padding: 60px 20px 0
  max-width: 1360px
  min-height: 100vh
  +transition
  //padding-bottom: 250px

  .portfolio &
    max-width: none

  @media screen and (min-width: $md)
    padding: 60px 60px 0
    +transition

  @media screen and (min-width: $lg)
    padding: 60px 120px 0
    +transition

  &__inner
    max-width: 1080px
    margin: 0 auto

    &--detail
      max-width: 1346px
      margin: 0 auto

    &--life
      max-width: 1087px
      margin: 0 auto

  &__background
    position: absolute
    top: 0%
    left: 4%
    height: auto
    width: 48%
    z-index: -1
    opacity: 0.04
    animation: 0.4s 0s scaleFromLeft forwards


.section.second
  background: $light-black
  display: flex
  justify-content: center
  align-items: center

.slogan
  display: inline
  font-weight: bold

  &__wrapper
    text-align: left

  &__main
    color: $white

    b
      color: $black


.code-example
  max-width: 186px

